import _ from "lodash";
import moment from "moment";
import React, { createContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { AiFillEye } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCaretDown } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { matchPath, useLocation, useNavigate } from "react-router";
import { Slide, toast } from "react-toastify";
import { ReactComponent as Check } from "../../assets/icons/approve.svg";
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg";
import { ReactComponent as Copy } from "../../assets/icons/copy-icon.svg";
import { ReactComponent as Export } from "../../assets/icons/csv-icon.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete-pos.svg";
import { ReactComponent as Setting } from "../../assets/icons/setting-blue.svg";
import { downloadTerminal } from "../../pages/BusinessReports/data";
import { ExportData } from "../../pages/BusinessReports/ExportData";
import axios from "../../plugins/axios";
import {
  activateTerminal,
  allMerchants,
  createterminal,
  deleteterminal,
  getTerminalsStats,
  issueterminal,
  terminalTypes,
  unassignterminal,
  updateterminal,
  viewAllTerminals,
} from "../../plugins/urls";
import { getBase64 } from "../../plugins/utils";
import TerminalCards from "../Cards/TerminalCards";
import Modal from "../Modal/Modal";
import NoResultFound from "../NoResultFound/NoResultFound";
import Pagination2 from "../Pagination/pagination2";
import CustomSpinner from "../Spinner/Spinner";
import Swal from "../Swal/swal";
import AddDevice from "./AddDevice";
import FilterPOS from "./FilterPOS";
import { getTerminalTypes } from "./shared";

export const PosDeviceContext = createContext();

const PosDevice = () => {
  const {
    user: { id },
  } = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();
  const terminalIdRef = useRef(null);
  const { pathname } = useLocation();
  const showForBusinessReport = matchPath("/business-reports", pathname);
  const [state, setState] = useState({
    add: false,
    modalValue: "",
    loading: false,
    // from: "",
    // to: "",
    pageNo: 0,
    pageSize: 10,
    totalPages: 0,
    totalRequests: 0,
    users: [],
    terminalList: [],
    terminalTypesList: [],
    userID: id,
    merchantId: "",
    merchantUserID: "",
    actualTerminalName: "",
    description: "",
    amountLeft: "",
    amountPaid: "",
    numberOfPaymentTime: 0,
    preferredTerminalName: "",
    terminalCost: "",
    image: null,
    terminalId: "",
    terminalSerialNumber: "",
    terminalType: "",
    submit: false,
    unassignedTerminals: [],
  });

  const [terminalsStats, setTerminalsStats] = useState({
    totalTerminals: 0,
    activeTerminals: 0,
    damagedTerminals: 0,
    suspendedTerminals: 0,
  });
  const [filterParams, setFilterParams] = useState({
    status: "",
    terminalName: "",
    startDate: "",
    endDate: "",
  });
  const [searchParams, setSearchParam] = useState("");

  const [filterFieldVal, setFilterFieldVal] = useState({
    field: "all",
    value: "all",
  });

  const {
    userID,
    merchantId,
    merchantUserID,
    terminalList,
    terminalTypesList,
    users,
    add,
    modalValue,
    actualTerminalName,
    amountPaid,
    amountLeft,
    description,
    numberOfPaymentTime,
    preferredTerminalName,
    terminalCost,
    terminalId,
    terminalSerialNumber,
    terminalType,
    image,
    pageNo,
    pageSize,
    totalPages,
    totalRequests,
    loading,
    submit,
  } = state;
  const {
    totalTerminals,
    activeTerminals,
    damagedTerminals,
    suspendedTerminals,
  } = terminalsStats;
  const [currentPage, setCurrentPage] = useState(1);

  const showModal = (value, terminal) => {
    if (!add) {
      setState((state) => ({
        ...state,
        add: true,
        modalValue: value,
        actualTerminalName: terminal
          ? terminal.actualTerminalName
            ? terminal.actualTerminalName
            : ""
          : "",
        description: terminal
          ? terminal.description
            ? terminal.description
            : ""
          : "",
        numberOfPaymentTime: terminal
          ? terminal.numberOfPaymentTime
            ? terminal.numberOfPaymentTime
            : ""
          : "",
        preferredTerminalName: terminal
          ? terminal.preferredTerminalName
            ? terminal.preferredTerminalName
            : ""
          : "",
        terminalCost: terminal
          ? terminal.terminalAmount
            ? terminal.terminalAmount
            : ""
          : "",
        terminalId: terminal
          ? terminal.terminalId
            ? terminal.terminalId
            : ""
          : "",
        terminalSerialNumber: terminal
          ? terminal.terminalSerialNumber
            ? terminal.terminalSerialNumber
            : ""
          : "",
        terminalType: terminal
          ? terminal.terminalType
            ? terminal.terminalType
            : ""
          : "",
        amountPaid: terminal
          ? terminal.amountPaid
            ? terminal.amountPaid
            : "0"
          : "",
        amountLeft: terminal
          ? terminal.amountLeft
            ? terminal.amountLeft
            : "0"
          : "",
        image: terminal ? (terminal.image ? terminal.image : "") : "",
        justId: terminal?.id ?? "",
      }));
    } else {
      setState((state) => ({
        ...state,
        add: false,
      }));
    }
  };

  useEffect(() => {
    getTerminalsStatistics();
    getUsers();
    getTerminalTypes({ setState });
  }, []);

  useEffect(() => {
    getTerminals();
  }, [pageNo, pageSize, searchParams]);

  const getTerminalsStatistics = () => {
    axios({
      method: "get",
      url: `${getTerminalsStats}`,
    })
      .then((res) => {
        if (res?.data?.respCode === 0) {
          let data = res.data.respBody;
          setTerminalsStats((terminalsStats) => ({
            ...terminalsStats,
            totalTerminals: data.totalTerminals ? data.totalTerminals : 0,
            activeTerminals: data.activeTerminals ? data.activeTerminals : 0,
            damagedTerminals: data.damagedTerminals ? data.damagedTerminals : 0,
            suspendedTerminals: data.suspendedTerminals
              ? data.suspendedTerminals
              : 0,
          }));
        }
      })
      .catch((err) => {
        if (err?.response?.data?.status === 401) {
          toast.error(`${err.response.data.error}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          navigate("/login");
          return;
        }
        toast.error(`${err?.response?.data?.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const getTerminals = (paramList = { field: "all", value: "all" }) => {
    let reqBody = {
      pageNo: pageNo,
      pageSize: pageSize,
      ...paramList,
    };

    axios({
      method: "post",
      url: `${viewAllTerminals}`,
      data: reqBody,
    })
      .then((res) => {
        if (res?.data?.respCode === 0) {
          setState((state) => ({
            ...state,
            terminalList: res.data.respBody.content,
            totalPages: res.data.respBody.totalPages,
            totalRequests: res.data.respBody.totalElements,
          }));
        }
      })
      .catch((err) => {
        if (err?.response?.data?.status === 401) {
          toast.error(`${err.response.data.error}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          navigate("/login");
          return;
        }
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const getUsers = () => {
    axios({
      method: "get",
      url: `${allMerchants}?size=1000`,
    })
      .then((res) => {
        if (res.data) {
          setState((state) => ({
            ...state,
            users: res.data.respBody,
          }));
        }
      })
      .catch((err) => {
        if (err?.response?.data?.status === 401) {
          toast.error(`${err.response.data.error}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          navigate("/login");
          return;
        }
        toast.error(`${err?.response?.data?.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "terminalType") {
      let valueObj = terminalTypesList.find(
        ({ terminalName }) => terminalName === value
      );
      setState((state) => ({
        ...state,
        terminalType: valueObj.terminalName,
        actualTerminalName: valueObj.terminalName,
        terminalCost: valueObj.amount,
      }));
    }
    if (name === "merchantUserID") {
      let valueObj = users.find(({ id }) => Number(id) === Number(value));
      setState((state) => ({
        ...state,
        merchantId: valueObj.merchantId,
        merchantUserID: valueObj.id,
      }));
    }
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onFilterChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "terminalType") {
      let valueObj = terminalTypesList.find(
        ({ id }) => Number(id) === Number(value)
      );
      console.log(valueObj);
      setState((state) => ({
        ...state,
        terminalType: valueObj.terminalName,
        actualTerminalName: valueObj.terminalName,
        terminalCost: valueObj.amount,
      }));
    }
    if (name === "merchantUserID") {
      let valueObj = users.find(({ id }) => Number(id) === Number(value));
      console.log(valueObj);
      setState((state) => ({
        ...state,
        merchantId: valueObj.merchantId,
        merchantUserID: valueObj.id,
      }));
    }
    setFilterParams((filterParams) => ({
      ...filterParams,
      [e.target.name]: e.target.value,
    }));
    setFilterFieldVal({ field: name, value });
  };

  const setStartDate = (date) => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      startDate: date,
    }));
    setFilterFieldVal({ field: "date", value: date });
  };

  const setEndDate = (date) => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      endDate: date,
    }));
  };

  const clearFilter = (e) => {
    e.preventDefault();
    setFilterParams((filterParams) => ({
      ...filterParams,
      startDate: "",
      endDate: "",
      terminalName: "",
      status: "",
    }));
    getTerminals();
  };

  const imageHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      let uploadedimage = e.target.files[0];
      // formData.append("image", uploadedimage);
      getBase64(uploadedimage)
        .then((result) => {
          uploadedimage["base64"] = result;
          setState((state) => ({
            ...state,
            image: uploadedimage.base64,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return;
    }
  };

  const onCreateTerminal = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));

    let reqBody = {
      actualTerminalName: actualTerminalName
        .trim()
        .replaceAll(/\s/g, "")
        .toUpperCase(),
      description,
      numberOfPaymentTime: Number(numberOfPaymentTime),
      preferredTerminalName,
      terminalCost,
      terminalId,
      terminalSerialNumber,
      // terminalType: terminalType.trim().replaceAll(/\s/g, "").toUpperCase(),
      terminalType: terminalType,
      image,
    };

    axios({
      method: "post",
      url: `${createterminal}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));

          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getTerminals();
        } else {
          toast.error(`${res.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(
          `${
            err.response.data
              ? err.response.data.message
                ? err.response.data.message
                : err.response.data.error
                ? err.response.data.error
                : "Unable to create new terminal"
              : "Unable to create new terminal"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };

  const onUpdateTerminal = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));

    let reqBody = {
      actualTerminalName: actualTerminalName
        .trim()
        .replaceAll(/\s/g, "")
        .toUpperCase(),
      description,
      numberOfPaymentTime: parseInt(numberOfPaymentTime),
      preferredTerminalName,
      terminalCost,
      terminalId,
      id: state?.justId,
      image,
      terminalSerialNumber,
      terminalType: terminalType.trim().replaceAll(/\s/g, "").toUpperCase(),
    };

    axios({
      method: "post",
      url: `${updateterminal}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));

          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getTerminals();
        } else {
          toast.error(`${res.data.respBody}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        if (err.response.statusCode === 401) {
          toast.error(`${err.response.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          navigate("/login");
        }

        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setState((state) => ({
          ...state,
          submit: false,
          add: false,
        }));
      });
  };

  const onDeleteTerminal = (id) => {
    // e.preventDefault();
    console.log("here", id);
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this terminal!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((remove) => {
      if (remove.isConfirmed) {
        console.log("here about to delete");
        setState((state) => ({
          ...state,
          submit: true,
        }));
        axios({
          method: "delete",
          url: `${deleteterminal}/${id}`,
        })
          .then((res) => {
            console.log("delete response", res);
            if (res.data.respCode === 0) {
              toast.success(`Terminal deleted Successfully`, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              });
              setState((state) => ({
                ...state,
                submit: false,
                add: false,
              }));
              getTerminals();
            } else {
              toast.error(`${res.data.message}`, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              });
              setState((state) => ({
                ...state,
                submit: false,
                add: false,
              }));
            }
          })
          .catch((err) => {
            if (err.response.statusCode === 401) {
              toast.error(
                `${
                  err.response.data.message
                    ? err.response.data.message
                    : "Unauthorized access. Please log in with the right access"
                }`,
                {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 3000,
                }
              );
              navigate("/login");
            }
            setState((state) => ({
              ...state,
              submit: false,
              add: false,
            }));
            console.log(err);
            toast.error(
              `${
                err.response.data.message
                  ? err.response.data.message
                  : "Unable to delete terminal"
              }`,
              {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              }
            );
          });
      }
    });
  };

  const onUnassignTerminal = (terminal) => {
    // e.preventDefault();
    const reqBody = {
      terminalId: terminal?.id,
    }
    console.log("terminial", terminal);
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to unassign this terminal!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((remove) => {
      if (remove.isConfirmed) {
        console.log("here about to unassign");
        setState((state) => ({
          ...state,
          submit: true,
        }));
        
        axios({
          method: "post",
          url: `${unassignterminal}`,
          data: reqBody,
        })
          .then((res) => {
            console.log("unassign response", res);
            if (res.data.respCode === 0) {
              toast.success(`Terminal unassigned Successfully`, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              });
              setState((state) => ({
                ...state,
                submit: false,
                add: false,
              }));
              getTerminals();
            } else {
              toast.error(`${res.data.message}`, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              });
              setState((state) => ({
                ...state,
                submit: false,
                add: false,
              }));
            }
          })
          .catch((err) => {
            if (err.response.statusCode === 401) {
              toast.error(
                `${
                  err.response.data.message
                    ? err.response.data.message
                    : "Unauthorized access. Please log in with the right access"
                }`,
                {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 3000,
                }
              );
              navigate("/login");
            }
            setState((state) => ({
              ...state,
              submit: false,
              add: false,
            }));
            console.log(err);
            toast.error(
              `${
                err.response.data.message
                  ? err.response.data.message
                  : "Unable to unassign terminal"
              }`,
              {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              }
            );
          });
      }
    });
  };

  const onIssueTerminal = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));

    let reqBody = {
      userID: Number(userID),
      merchantId,
      merchantUserID: Number(merchantUserID),
      actualTerminalName: actualTerminalName
        .trim()
        .replaceAll(/\s/g, "")
        .toUpperCase(),
      preferredTerminalName,
      amountLeft,
      amountPaid,
      terminalCost,
      terminalId,
    };

    axios({
      method: "post",
      url: `${issueterminal}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));

          toast.success(`Terminal Issued Successfully`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getTerminals();
        } else {
          toast.error(
            `${res.data ? res.data.message : "Something  went wrong"}`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
          setState((state) => ({
            ...state,
            submit: false,
          }));
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        console.log(err.response);
        // toast.error(`${err.response.data.message}`, {
        //   transition: Slide,
        //   hideProgressBar: true,
        //   autoClose: 3000,
        // });
      });
  };

  const onActivateTerminal = (id, status) => {
    if (status) {
      if (status.toLowerCase() === "activated") {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to deactivate this terminal!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then((remove) => {
          if (remove.isConfirmed) {
            let reqBody = {
              terminalId: id,
              status: "DEACTIVATED",
            };
            axios({
              method: "post",
              url: `${activateTerminal}`,
              headers: {
                "Content-Type": "application/json",
              },
              data: reqBody,
            })
              .then((res) => {
                if (res.data.respCode === 0) {
                  toast.success(`Terminal Deactivated successfully`, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                  getTerminals();
                }
              })
              .catch((err) => {
                toast.error(`${err.response.data.message}`, {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              });
          }
        });
      } else if (status.toLowerCase() === "deactivated") {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to Activate this terminal!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then((remove) => {
          if (remove.isConfirmed) {
            let reqBody = {
              terminalId: id,
              status: "ACTIVATED",
            };
            axios({
              method: "post",
              url: `${activateTerminal}`,
              headers: {
                "Content-Type": "application/json",
              },
              data: reqBody,
            })
              .then((res) => {
                if (res.data.respCode === 0) {
                  toast.success(`Terminal Activated successfully`, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                  getTerminals();
                }
              })
              .catch((err) => {
                toast.error(`${err.response.data.message}`, {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              });
          }
        });
      }
    }
  };

  const headers = [
    { label: "Terminal ID", key: "terminalId" },
    { label: "Terminal Name", key: "actualTerminalName" },
    { label: "Terminal Amount", key: "terminalAmount" },
    { label: "orgName", key: "orgName" },
    { label: "Status", key: "status" },
    { label: "Issue Date", key: "issuedDate" },
  ];

  const csvReport = {
    data: terminalList,
    headers: headers,
    filename: "WayaPosDevices_Report.csv",
  };

  const lastIndex = (pageNo + 1) * pageSize;
  const firstIndex = lastIndex + 1 - pageSize;
  const paginate = (ev) => {
    let selected = ev.selected;
    setState((state) => ({
      ...state,
      pageNo: selected,
    }));
    setCurrentPage(selected + 1);
  };
  const noPerPage = (no) => {
    setState((state) => ({
      ...state,
      pageSize: no,
    }));
  };

  const copyTerminalId = (id) => {
    navigator.clipboard.writeText(id);
    console.log(id);
  };
  return (
    <PosDeviceContext.Provider
      value={{
        onChange,
        onFilterChange,
        imageHandler,
        state,
        filterParams,
        setStartDate,
        setEndDate,
        clearFilter,
        getTerminals,
        terminalTypesList,
        filterFieldVal,
      }}
    >
      <Modal
        show={add}
        clicked={showModal}
        submit={
          modalValue === "add"
            ? onCreateTerminal
            : modalValue === "issue"
            ? onIssueTerminal
            : onUpdateTerminal
        }
        loading={submit}
        title={
          modalValue === "add"
            ? "Add new terminal device"
            : modalValue === "issue"
            ? "Issue Terminal"
            : "Update Terminal"
        }
        action={
          modalValue === "add"
            ? "Add Terminal"
            : modalValue === "issue"
            ? " Issue Terminal"
            : "Update Terminal"
        }
        close="Cancel"
      >
        <AddDevice />
      </Modal>
      <div className="w-100 d-flex filter-contents justify-content-between align-items-center">
        <div className="d-flex justify-content-center align-items-center ">
          <FilterPOS />
          <div className="muted-input d-flex justify-content-center align-items-center">
            <FiSearch color="#B5B5B5" />
            <input
              className="input ml-10 "
              placeholder="Search with terminal id or merchant id"
              onChange={(e) => setSearchParam(e.target.value)}
            />
          </div>
          <button
            className="orange-button ml-10"
            onClick={() =>
              getTerminals({ field: "terminalId", value: searchParams })
            }
            disabled={!Boolean(searchParams)}
          >
            Search
          </button>
        </div>
        <div className="d-flex justify-content-end align-items-center ">
          {_.isEmpty(showForBusinessReport) && (
            <button
              className="request-button fs-13 fw-700"
              onClick={() => showModal("add")}
            >
              ADD NEW TERMINAL
            </button>
          )}
          {/* <div className="export-button fs-13 fw-700">
            <CSVLink
              {...csvReport}
              style={{ color: "#FF6700", textDecoration: "none" }}
            >
              <Export className="me-3" />
              Export
              <i className="ms-2 text-black">
                <FaCaretDown />
              </i>
            </CSVLink>
          </div> */}

          <ExportData
            name={"POSDevice"}
            url={downloadTerminal}
            filename={`POS device terminal report-${new Date().toISOString()}`}
          />
        </div>
      </div>

      <Container fluid>
        <Row className="mt-40">
          <Col>
            <TerminalCards
              title="Total Terminals Onboarded"
              value={totalTerminals}
              color="text-grey"
              textColor="text-grey"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Total Active Terminals"
              value={activeTerminals}
              color="text-grey"
              textColor="text-grey"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Total  Suspended Terminals"
              value={suspendedTerminals}
              color="text-grey"
              textColor="text-grey"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Total  Damaged Terminals"
              value={damagedTerminals}
              color="text-grey"
              textColor="text-grey"
            />
          </Col>
        </Row>

        <div className="  mt-40 ">
          <Table responsive borderless className="bg-inherit">
            <thead>
              <tr className=" t-head-colored">
                <th scope="col" className="py-2 th ">
                  S/N
                </th>
                <th scope="col" className="py-2 th ">
                  TERMINAL ID
                </th>
                <th scope="col" className="py-2 th ">
                  FIRST NAME
                </th>
                <th scope="col" className="py-2 th ">
                  LAST NAME
                </th>
                <th scope="col" className="py-2 th ">
                  EMAIL
                </th>
                <th scope="col" className="py-2 th ">
                  BUSINESS TYPE
                </th>                
                <th scope="col" className="py-2 th ">
                  PHONE NUMBER
                </th> 
                <th scope="col" className="py-2 th ">
                  TERMINAL SERIAL NO.
                </th>
                <th scope="col" className="py-2 th ">
                  TERMINAL NAME
                </th>
                <th scope="col" className="py-2 th ">
                  TERMINAL COST
                </th>
                <th scope="col" className="py-2 th ">
                  ASSIGNED TO
                </th>
                <th scope="col" className="py-2 th ">
                  STATUS
                </th>
                <th scope="col" className="py-2 th ">
                  ISSUED DATE
                </th>
                <th scope="col" className="py-2 th ">
                  ACTIONS
                </th>
              </tr>
            </thead>
            <tbody>
              {terminalList ? (
                terminalList.length === 0 ? (
                  <NoResultFound />
                ) : (
                  terminalList.map((terminal, i) => {
                    const {
                      terminalId,
                      actualTerminalName,
                      orgName,
                      terminalAmount,
                      status,
                      issuedDate,
                      merchants,
                      terminalSerialNumber,
                    } = terminal;
                    const statusClass = (status) => {
                      if (status === "ACTIVATED") {
                        return "tabactive";
                      } else if (status === "DEACTIVATED") {
                        return "tabdanger";
                      } else {
                        return "tabdamaged";
                      }
                    };

                    return (
                      <tr key={i}>
                        <td className=" td text-nowrap text-smaller px-1">
                          {i + 1}
                        </td>
                        <td className=" td text-nowrap text-smaller">
                          {terminalId ? (
                            <span
                              ref={terminalIdRef}
                              className="d-flex justify-content-between align-items-center w-75"
                            >
                              {terminalId}{" "}
                              <i
                                className="ms-1"
                                onClick={() => copyTerminalId(terminalId)}
                              >
                                <Copy />
                              </i>
                            </span>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td className=" td text-wrap text-smaller">
                          {merchants?.firstname ?? "N/A"}
                        </td>
                        <td className=" td text-wrap text-smaller">
                          {merchants?.surname ?? "N/A"}
                        </td>
                        <td className=" td text-wrap text-smaller">
                          {merchants?.email ?? "N/A"}
                        </td>
                        <td className=" td text-wrap text-smaller">
                          {merchants?.orgType ?? "N/A"}
                        </td>
                        <td className=" td text-wrap text-smaller">
                          {merchants?.phoneNumber ?? "N/A"}
                        </td>
                        <td className=" td text-wrap text-smaller">
                          {terminalSerialNumber ?? "N/A"}
                        </td>
                        <td className=" td text-wrap text-smaller">
                          {actualTerminalName ? actualTerminalName : "N/A"}
                        </td>
                        <td className=" td text-wrap text-smaller">
                          {terminalAmount ? (
                            <span>NGN {terminalAmount}</span>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td className=" td text-wrap text-smaller">
                          {merchants?.orgName ?? "N/A"}
                        </td>
                        <td className=" td text-wrap text-smaller">
                          <span className={`${statusClass(status)}`}>
                            {status}
                          </span>
                        </td>
                        <td className=" td text-wrap text-smaller">
                          {issuedDate
                            ? moment(new Date(issuedDate)).format(
                                "MMMM Do YYYY, hh:mm A"
                              )
                            : "N/A"}
                        </td>
                        <td className=" td text-nowrap px-0 text-smaller">
                          <span
                            className={`action-purple ms-2 ${
                              merchants?.orgName ? "" : "  opaque no-click"
                            }`}
                            // onClick={() => showModal("edit", terminal)}
                          >
                            <AiFillEye size={12} color="#644CFA" />
                          </span>
                          <span
                            className="action-lightBlue ms-2"
                            onClick={() => showModal("update", terminal)}
                          >
                            <Setting size={12} color="#064A72" /> Edit
                          </span>
                          {status ? (
                            status.toLowerCase() === "activated" ? (
                              <span
                                className={`actionDanger ms-2 `}
                                onClick={() =>
                                  onActivateTerminal(terminalId, status)
                                }
                              >
                                <Cancel size={12} color="#FF6700" /> Deactivate
                              </span>
                            ) : (
                              <span
                                className={`actionSuccess ms-2 `}
                                onClick={() =>
                                  onActivateTerminal(terminalId, status)
                                }
                              >
                                <Check size={12} color="#FF6700" /> Activate
                              </span>
                            )
                          ) : null}
                          {/* {assignedTo ? null: <span className="actionSuccess ml-10" onClick={()=>showModal('issue', terminal)}><Check size={20} c/> Issue</span>} */}
                          <i
                            className={` ms-2 ${
                              merchants?.orgName ? "" : "  opaque no-click"
                            }`}
                            onClick={
                              merchants?.orgName
                                ? () => onDeleteTerminal(terminalId)
                                : null
                            }
                          >
                            <Delete size={12} />
                          </i>
                          <div className="dropdown ms-2">
                            <i
                              className=" dropdown"
                              id="terminal-config"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <BsThreeDotsVertical size={12} />
                            </i>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <span
                                className="dropdown-item fs-12 "
                                href="#!"
                                onClick={() => showModal("update", terminal)}
                              >
                                Update Terminal Configuration
                              </span>
                              <span
                                className="dropdown-item fs-12 "
                                href="#!"
                                onClick={() => onUnassignTerminal(terminal)}
                              >
                                Unassign Terminal
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                <NoResultFound />
              )}
            </tbody>
          </Table>
        </div>
        <div>
          {loading ? (
            <CustomSpinner />
          ) : terminalList && terminalList.length > 0 ? (
            <div className="t-head-colored mt-4 py-2 d-flex justify-content-center align-items-center w-100">
              <div className="d-flex me-5">
                <p className="m-0 p-0 me-4">Rows per page : {pageSize}</p>
                <div className="dropdown">
                  <i
                    id="addminuserdropdownMenuButton1"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FaCaretDown />
                  </i>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="adminuserdropdownMenuButton1"
                  >
                    <li className="dropdown-item" onClick={() => noPerPage(4)}>
                      4
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(10)}>
                      10
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(20)}>
                      20
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(50)}>
                      50
                    </li>
                  </ul>
                </div>
              </div>
              <div className="me-4">
                <p className="m-0">
                  {firstIndex} - {lastIndex} of {totalRequests}
                </p>
              </div>
              <div className="mt-3 me-2">
                <Pagination2
                  total={totalPages}
                  paginate={paginate}
                  selected={currentPage}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Container>
    </PosDeviceContext.Provider>
  );
};

export default PosDevice;
