import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { BiLinkExternal } from "react-icons/bi";
import { Slide, toast } from "react-toastify";
import Layout from "../../components/Layout/Layout";
import swal from "../../components/Swal/swal";
import SimpleDateFilter from "../../components/UI/DateFilter";
import SimpleDropDown from "../../components/UI/DropDown";
import SimpleCard from "../../components/UI/SimpleCard";
import SimpleSearchBox from "../../components/UI/SimpleSearchBox";
import SimpleTable from "../../components/UI/Tables/Table";
import { allRequestLogs, filterLogs, updateLogs } from "../../plugins/urls";
import { apiCall } from "../../utilities/apis";
import { ExportToExcel } from "../../utilities/downloadExcelFron";
import { camelToString, sentenceCaps } from "../../utilities/stringOperations";
import { downloadRequestLog } from "../BusinessReports/data";
import { ExportData } from "../BusinessReports/ExportData";
import "./styles.scss";

const ThreeDot = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-three-dots-vertical"
      viewBox="0 0 16 16"
    >
      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
    </svg>
  );
};
const DropDownAction = ({ data, setLoading, reload, setReload }) => {
  console.log(data);
  const handleApprove = (e, status) => {
    if (status === "REJECTED") {
      swal
        .fire({
          title: "Provide reason for rejection",
          icon: "error",
          input: "text",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            setLoading(true);
            axios
              .put(`${updateLogs}/${data?.id}`, {
                status,
                reason: result.value,
              })
              .then((res) => {
                if (res.status === 200 && res.data.respCode === 0) {
                  setReload(!reload);
                  toast.success(res.data?.respDesc, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast.error(res.data.respDesc, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }
        });
    } else {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Approve it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            setLoading(true);
            axios
              .put(`${updateLogs}/${data?.id}`, {
                status,
                reason: "",
              })
              .then((res) => {
                if (res.status === 200 && res.data.respCode === 0) {
                  setReload(!reload);
                  toast.success(res.data.respDesc, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else if (res.data.respCode === 96) {
                  toast.error(res.data.respBody ?? res.data.respDesc, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast.error(res.data.respDesc, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }
              })
              .finally(() => {
                setLoading(false);
              });
          }
        });
    }
  };

  return (
    <Dropdown className="dd">
      <Dropdown.Toggle variant={"outline"}>{<ThreeDot />}</Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={(e) => handleApprove(e, "APPROVED")}>
          {"Approve"}
        </Dropdown.Item>
        <Dropdown.Item onClick={(e) => handleApprove(e, "REJECTED")}>
          {"Reject"}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const tableCols = (setLoading, reload, setReload) => [
  {
    value: "id",
    name: "Request ID",
  },
  {
    value: "requestBy",
    name: "Request By",
  },
  {
    value: "reason",
    name: "Reason",
  },
  {
    value: "requestType",
    name: "Request Type",
    type: "status",
  },
  {
    value: "status",
    name: "Status",
    type: "status",
  },
  {
    value: "createdAt",
    type: "date",
    name: "Date",
  },
  {
    Comp: (data, setLoading) => (
      <DropDownAction data={data} setLoading={setLoading} reload={reload} setReload={setReload} />
    ),
    type: "action",
    name: "Action",
  },
];
const filters = ["activity", "duration", "adminName", "adminEmail"];
const roles = [
  "admin",
  "super admin",
  "executive admin",
  "merchant",
  "developer",
];

const RequestLogs = ({ hidden }) => {
  const [formData, setFormData] = useState({
    pageSize: 10,
    pageNo: 1,
    from: "",
    to: "",
  });
  const [tableData, setTableData] = useState({});
  const [role, setRole] = useState("");
  const [filter, setFilter] = useState(filters[0]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const getAllLogs = ({ pageNumber = 1, pageSize = 10 }) => {
    const data = {
      ...formData,
    };
    apiCall({
      url: `${allRequestLogs}?pageSize=${pageSize}&pageNo=${pageNumber}`,
      success: (data) => setTableData(data),
      setLoading,
      data,
      method: "get",
      meta: {
        dataIndex: "respBody",
        codeIndex: "respCode",
        messageIndex: "respDesc",
      },
    });
  };
  const filterTerm = (value, field) => {
    apiCall({
      url: `${filterLogs}?field=${field}&value=${value}&pageSize=${formData.pageSize}&pageNo=${formData.pageNo}`,
      success: (data) => {
        setTableData(data);
        setSearchTerm("");
      },
      setLoading,
      method: "post",
      meta: {
        dataIndex: "respBody",
        codeIndex: "respCode",
        messageIndex: "respDesc",
      },
    });
  };

  useEffect(() => {
    if (searchTerm !== "") {
      filterTerm(searchTerm, filter);
    } else getAllLogs({});
  }, [formData, reload]);
  useEffect(() => {
    if (searchTerm !== "") filterTerm(searchTerm, filter);
  }, [filter, searchTerm]);

  //business logic

  const setEntity = (name, value) =>
    setFormData({ ...formData, [name]: value });
  const setDateFilter = (from, to) => setFormData({ ...formData, from, to });
  const clearSearch = () => {
    setSearchTerm("");
    getAllLogs({});
  };

  return (
    <Layout title="Request Log" hidden={hidden}>
      <div className="d-flex justify-content-between">
        <div style={{ width: 435 }}>
          <div className="d-flex">
            <SimpleSearchBox
              items={filters}
              setSelected={setFilter}
              selected={filter}
              filter
              camel
              handleSearch={setSearchTerm}
              clearSearch={clearSearch}
              placeholder={
                filter === "Filter"
                  ? filter
                  : `Filter by ${sentenceCaps(camelToString(filter))}`
              }
            />
          </div>
        </div>

        {tableData?.content?.length > 0 && (
          <div className="d-flex justify-content-center align-items-center ">
            <ExportData
              url={downloadRequestLog}
              name="requestLog"
              filename={`Waya request-log report-${new Date().toISOString()}`}
            />
          </div>
        )}
      </div>
      <div className="d-flex my-3">
        <SimpleCard
          title="Total System Log"
          body={tableData?.totalElements ?? 0}
        />
      </div>
      <div className="col-md-5">
        <div className=" d-flex flex-wrap " style={{ minWidth: 435 }}>
          <div className="me-2">
            <SimpleDropDown
              items={roles}
              selected={role || "Role Type"}
              setSelected={setRole}
              clss="border collapse-btn d-flex justify-content-between text-muted"
            />
          </div>
          <div className="">
            <SimpleDateFilter setDateFilter={setDateFilter} />
          </div>
        </div>
      </div>

      {loading ? (
        <div className="align-items-center d-flex justify-content-center">
          <Spinner animation="border" role="status" variant="success" />
        </div>
      ) : (
        <Container fluid>
          <div className="data-table mt-40">
            <SimpleTable
              setPageFn={getAllLogs}
              cols={tableCols(setLoading, reload, setReload)}
              tableData={tableData}
              setEntity={setEntity}
              setLoading={setLoading}
            />
          </div>
        </Container>
      )}
    </Layout>
  );
};

export default RequestLogs;
