import axios from "axios";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  getMerchantBankWalletDetails,
  getMerchantWalletDetails,
  requestTerminal,
  viewMerchant,
} from "../../plugins/urls";
import FilterSearchMerchant from "./FilterSearchMerchant";
import { getTerminalTypes } from "./shared";
import "./styles.scss";

const TerminalSelector = ({
  name,
  quantity,
  setSummary,
  amount,
  otherTerminalDetails,
}) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (count > 0) {
      setSummary((s) => ({
        ...s,
        [name]: {
          count,
          amount: amount * count,
          pos: {
            ...otherTerminalDetails,
            amount,
            quantity: count,
            terminalName: name,
          },
        },
      }));
    } else {
      setSummary((summary) => {
        const copy = { ...summary };
        delete copy[name];
        return copy;
      });
      setCount(0);
    }
  }, [count]);

  const handleChange = (e) => {
    const { value } = e.target;
    const count = value;
    setCount(count <= quantity ? count : quantity);
  };
  return (
    <span>
      <button
        className="minus"
        onClick={() => setCount(count > 0 ? count - 1 : 0)}
      >
        -
      </button>

      <input
        value={count}
        defaultValue={count}
        onChange={handleChange}
        className={"counter"}
      ></input>
      <button
        className="plus"
        onClick={() => setCount(count < quantity ? count + 1 : quantity)}
      >
        +
      </button>
    </span>
  );
};

const RequestTerminalForMerchant = ({ show, setShowFn }) => {
  const [state, setState] = useState({ terminalTypesList: [] });
  const [summary, setSummary] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [isCharged, setIsCharge] = useState("debitMe");
  const [wallets, setWallets] = useState([])
  const [debitWallet, setDebitWallet]= useState();
  const [settlementAccount, setSettlementAccount] = useState();

  const getMerchantBankDetails = () => {
    console.log({selectedValue});
    axios({
      method: "get",
      url: `${getMerchantBankWalletDetails(selectedValue[0].userId)}`,
    })
      .then((res) => {
        if (res.data.status === true) {
          setWallets(res.data.data)
        } else {
          
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  useEffect(() => {
    getTerminalTypes({ setState });
  }, []);

  useEffect(() => {
    setWallets([]);
    if (selectedValue) getMerchantBankDetails();
  }, [selectedValue]);

  const { terminalTypesList } = state;

  const total = Object.values(summary).reduce(
    (acc, curr) => acc + curr?.amount,
    0
  );

  const handleDebitChange = (e) => {
    setIsCharge(e.target.value);
  };

  const handleClick = () => {
    setLoading(true);
    if (isEmpty(summary)) {
      toast.error("You need to select terminals");
      setLoading(false);
      return;
    }
    if (isEmpty(selectedValue)) {
      toast.error("You need to select merchants");
      setLoading(false);
      return;
    }

    const endpoints = [];
    selectedValue.map((merhant) => {
      const { merchantId, merchantUserId } = merhant;
      endpoints.push({
        merchantId,
        merchantUserId,
        url: getMerchantWalletDetails(merchantId),
      });
    });
    try {
      axios
        .all(
          endpoints.map(({ url, merchantId }) => ({
            resp: axios.get(url),
            merchantId,
          }))
        )
        .then((result) => {
          result?.map(({ resp, merchantId }) => {
            resp.then(async (res) => {
              if (isCharged === "debitMe" && res.data.respCode === 96) {
                toast.error(res.data.respDesc);
              } else {
                let merchantDetail;
                const {
                  bankDetail: { accountNumber, accountName },
                  activeBalance,
                } = res.data.respBody;

                // if (isCharged === "debitMe")
                //   return toast.error(`Insufficient Balance for ${accountName}`);
                await axios
                  .get(`${viewMerchant}?merchantId=${merchantId}`)
                  .then((res) => {
                    const { officeAddress, phoneNumber } = res.data.respBody;
                    merchantDetail = {
                      deliveryAddress: officeAddress,
                      phoneNumber,
                    };
                  });
                axios
                  .post(requestTerminal, {
                    accountToDebit: debitWallet,
                    settlementAccount,
                    ...merchantDetail,
                    fullOrPartPayment: "FULL",
                    merchantId,
                    isCharged: isCharged === "debitMe",
                    subTotal: total,
                    terminalTypes: Object.values(summary).map(({ pos }) => pos),
                  })
                  .then((res) => {
                    if (res.status === 200 && res.data.respCode === 0) {
                      toast.success(res.data.respDesc);
                      setShowFn(false)
                    } else {
                      toast.error(res.data.respDesc);
                    }
                  })
                  .catch((err) => {
                    toast.error(err.response.data.respDesc);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }
            });
          });
        });
    } catch {
      toast.error("An Error occurred");
    }
  };
  return (
    <Modal
      show={show}
      onHide={() => setShowFn(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="border-bottom-0 p-4 shadow rounded border-light">
        <Modal.Title className=" m-auto fs-18">
          REQUEST TERMINAL FOR MERCHANT
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-14  shadow">
        {terminalTypesList.map(
          ({ terminalName, quantity, amount, ...rest }) => (
            <Card>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>{terminalName}</span>
                <TerminalSelector
                  name={terminalName}
                  quantity={quantity}
                  setSummary={setSummary}
                  amount={amount}
                  otherTerminalDetails={rest}
                />
              </div>
            </Card>
          )
        )}
        <FilterSearchMerchant setSelectedValue={setSelectedValue} />
        <div className="inputGroup">
          <label> Select Wallet to debit</label>
          <select
            className="input select w-100 border"
            onChange={(e) => setDebitWallet(e.target.value)}
          >
            <option>...</option>
            {wallets?.map((e) =>
              <option value={e?.accountNo}>
                {e?.accountNo}-{e?.acct_name} -N{e?.clr_bal_amt}
                </option>
            )}
          </select>
        </div>
        <div className="inputGroup">
          <label> Select account you want attach to terminal</label>
          <select
            className="input select w-100 border"
            onChange={(e) => setSettlementAccount(e.target.value)}
          >
            <option>...</option>
            {wallets?.map((e) =>
              <option value={e?.accountNo}>
                {e?.accountNo}-{e?.acct_name} -N{e?.clr_bal_amt}
                </option>
            )}
          </select>
        </div>

        <div className="summary">
          <div>
            <span>{"Total terminal Request : "}</span>
            <span>
              {Object.values(summary).reduce(
                (acc, curr) => acc + curr?.count,
                0
              )}
            </span>
          </div>
          <div>
            <span>{"Caution Fee : NGN "}</span>
            {Object.values(summary).reduce(
              (acc, curr) => acc + curr?.amount,
              0
            )}
          </div>
        </div>

        <div className="debit" onChange={handleDebitChange}>
          <div className="inputGroup">
            <input
              type="radio"
              value={"debitMe"}
              name="isCharged"
              id="debitMe"
              checked={isCharged === "debitMe"}
            />
            <label for="debitMe">Debit Customer</label>
          </div>

          <div className="inputGroup">
            <input
              type="radio"
              value={"dontDebitMe"}
              name="isCharged"
              id="dontDebitMe"
              checked={isCharged === "dontDebitMe"}
            />
            <label for="dontDebitMe">Don't Debit Customer</label>
          </div>
        </div>
        <div className="request-box">
          <button disabled={loading} className="orange-button" onClick={handleClick}>
            {"Request for Terminal"}{" "}
            {loading && (
              <span>
                <Spinner size={"sm"} />
              </span>
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RequestTerminalForMerchant;
